import React from 'react';
import ManageServiceBrands from './ManageServiceBrands';
import ManageSubBrands from '../../Brands/BrandCrud/ManageSubBrands';
import './ListServices.css';

const ListServices = ({ services, onEdit, onDelete, onRefresh }) => {
  return (
    <div className="list-services">
      <h3>Services</h3>
      <div className="services-container">
        {services.map((service) => (
          <div key={service._id} className="service-card">
            {/* Service Header */}
            <div className="service-header">
              <h4>{service.name}</h4>
              <div className="service-actions">
                <button
                  className="edit-button"
                  onClick={() => onEdit(service)}
                >
                  Edit
                </button>
                <button
                  className="delete-button"
                  onClick={() => onDelete(service)}
                >
                  Delete
                </button>
              </div>
            </div>

            {/* Manage Brands */}
            <div className="service-brands">
              <h5>Manage Brands</h5>
              <ManageServiceBrands service={service} onRefresh={onRefresh} />
            </div>
        
          </div>
        ))}
      </div>
    </div>
  );
};

export default ListServices;
