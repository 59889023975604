import React, { useState, useEffect } from 'react';
import axiosAppointmentInstance from '../../../utils/axiosAppointment';
import AddBrand from '../../Brands/BrandCrud/AddBrand';
import { toast } from 'react-toastify';
import './ManageServiceBrands.css';

const ManageServiceBrands = ({ service, onRefresh }) => {
  const [associatedBrands, setAssociatedBrands] = useState([]);
  const [allBrands, setAllBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState('');
  const [showAddBrandModal, setShowAddBrandModal] = useState(false);
  const [selectedBrandId, setSelectedBrandId] = useState(null);
  const [associatedSubBrands, setAssociatedSubBrands] = useState([]);
  const [allSubBrands, setAllSubBrands] = useState([]);
  const [selectedSubBrand, setSelectedSubBrand] = useState('');

  useEffect(() => {
    fetchAssociatedBrands();
    fetchAllBrands();
  }, []);

  // Fetch associated brands for the service
  const fetchAssociatedBrands = async () => {
    try {
      const response = await axiosAppointmentInstance.get(
        `/services/${service._id}/brands`
      );
      setAssociatedBrands(response.data);
    } catch (error) {
      console.error('Error fetching associated brands:', error);
      toast.error('Failed to fetch associated brands.');
    }
  };

  // Fetch all available brands
  const fetchAllBrands = async () => {
    try {
      const response = await axiosAppointmentInstance.get('/brands');
      setAllBrands(response.data);
    } catch (error) {
      console.error('Error fetching all brands:', error);
      toast.error('Failed to fetch all brands.');
    }
  };

  // Fetch associated sub-brands for a selected brand under the service
  const fetchAssociatedSubBrands = async (brandId) => {
    try {
      const response = await axiosAppointmentInstance.get(
        `/services/${service._id}/brands/${brandId}/sub-brands`
      );
      setAssociatedSubBrands(response.data);
    } catch (error) {
      console.error('Error fetching associated sub-brands:', error);
      toast.error('Failed to fetch associated sub-brands.');
    }
  };

  // Fetch all available sub-brands
  const fetchAllSubBrands = async () => {
    try {
      const response = await axiosAppointmentInstance.get('/sub-brands');
      setAllSubBrands(response.data);
    } catch (error) {
      console.error('Error fetching all sub-brands:', error);
      toast.error('Failed to fetch all sub-brands.');
    }
  };

  // Add brand to service
  const handleAddBrand = async () => {
    if (!selectedBrand) {
      toast.error('Please select a brand to add.');
      return;
    }
    try {
      await axiosAppointmentInstance.put(
        `/services/${service._id}/add-brand/${selectedBrand}`
      );
      toast.success('Brand added to service!');
      setSelectedBrand('');
      fetchAssociatedBrands();
      onRefresh();
    } catch (error) {
      console.error('Error adding brand:', error);
      toast.error('Failed to add brand to service.');
    }
  };

  // Remove brand from service
  const handleRemoveBrand = async (brandId) => {
    try {
      await axiosAppointmentInstance.put(
        `/services/${service._id}/remove-brand/${brandId}`
      );
      toast.success('Brand removed from service!');
      fetchAssociatedBrands();
      onRefresh();
    } catch (error) {
      console.error('Error removing brand:', error);
      toast.error('Failed to remove brand from service.');
    }
  };

  // Add sub-brand to service under a brand
  const handleAddSubBrand = async (brandId) => {
    if (!selectedSubBrand) {
      toast.error('Please select a sub-brand to add.');
      return;
    }
    try {
      await axiosAppointmentInstance.put(
        `/services/${service._id}/brands/${brandId}/add-sub-brand/${selectedSubBrand}`
      );
      toast.success('Sub-brand added successfully!');
      setSelectedSubBrand('');
      fetchAssociatedSubBrands(brandId);
    } catch (error) {
      console.error('Error adding sub-brand:', error);
      toast.error('Failed to add sub-brand.');
    }
  };

  // Remove sub-brand from service under a brand
  const handleRemoveSubBrand = async (brandId, subBrandId) => {
    try {
      await axiosAppointmentInstance.put(
        `/services/${service._id}/brands/${brandId}/remove-sub-brand/${subBrandId}`
      );
      toast.success('Sub-brand removed successfully!');
      fetchAssociatedSubBrands(brandId);
    } catch (error) {
      console.error('Error removing sub-brand:', error);
      toast.error('Failed to remove sub-brand.');
    }
  };

  return (
    <div className="manage-service-brands">
      <h4>Manage Brands for {service.name}</h4>

      {/* Associated Brands */}
      <ul className="associated-brands-list">
        {associatedBrands.map((brand) => (
          <li key={brand._id}>
            <div>
              <strong>{brand.name}</strong>
              <button
                className="remove-brand-button"
                onClick={() => handleRemoveBrand(brand._id)}
              >
                Remove
              </button>
              <button
                onClick={() => {
                  setSelectedBrandId(brand._id);
                  fetchAssociatedSubBrands(brand._id);
                }}
              >
                Manage Sub-Brands
              </button>
            </div>
            {selectedBrandId === brand._id && (
              <div className="sub-brand-section">
                <h5>Sub-Brands</h5>
                <ul>
                  {associatedSubBrands.map((subBrand) => (
                    <li key={subBrand._id}>
                      {subBrand.name}
                      <button
                        onClick={() => handleRemoveSubBrand(brand._id, subBrand._id)}
                      >
                        Remove
                      </button>
                    </li>
                  ))}
                </ul>
                <div className="add-brand-dropdown">
                  <select
                    value={selectedSubBrand}
                    onChange={(e) => setSelectedSubBrand(e.target.value)}
                  >
                    <option value="">Select a Sub-Brand to Add</option>
                    {allSubBrands.map((subBrand) => (
                      <option key={subBrand._id} value={subBrand._id}>
                        {subBrand.name}
                      </option>
                    ))}
                  </select>
                  <button onClick={() => handleAddSubBrand(brand._id)}>Add</button>
                </div>
              </div>
            )}
          </li>
        ))}
      </ul>

      {/* Add Existing Brand */}
      <div className="add-brand-dropdown">
        <select
          value={selectedBrand}
          onChange={(e) => setSelectedBrand(e.target.value)}
        >
          <option value="">Select a Brand to Add</option>
          {allBrands.map((brand) => (
            <option key={brand._id} value={brand._id}>
              {brand.name}
            </option>
          ))}
        </select>
        <button onClick={handleAddBrand}>Add</button>
        <button onClick={() => setShowAddBrandModal(true)}>Add New Brand</button>
      </div>

      {/* Add Brand Modal */}
      {showAddBrandModal && (
        <AddBrand
          onClose={() => {
            setShowAddBrandModal(false);
            fetchAllBrands();
          }}
          onRefresh={onRefresh}
        />
      )}
    </div>
  );
};

export default ManageServiceBrands;
