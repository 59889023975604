// VehicleSearch.jsx
import React, { useState, useEffect } from 'react';
import axiosVehicle from '../../utils/axiosVehicle';
import './VehicleSearch.css';

function VehicleSearch({ onVehicleSelect }) {
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedMake, setSelectedMake] = useState('');
  const [selectedModel, setSelectedModel] = useState('');
  const [selectedTrim, setSelectedTrim] = useState('');
  const [makes, setMakes] = useState([]);
  const [models, setModels] = useState([]);
  const [trims, setTrims] = useState([]);

  const fetchMongoDBData = async (url, setState, params = {}) => {
    try {
      const response = await axiosVehicle.get(url, { params });
      setState(response.data);
    } catch (error) {
      console.error('Error fetching MongoDB data:', error);
    }
  };

  useEffect(() => {
    if (selectedYear) {
      fetchMongoDBData('/vehicles/search', setMakes, { year: selectedYear });
    } else {
      setMakes([]);
    }
  }, [selectedYear]);
  
  useEffect(() => {
    if (selectedYear && selectedMake) {
      fetchMongoDBData('/vehicles/search', setModels, { year: selectedYear, make: selectedMake });
    } else {
      setModels([]);
    }
  }, [selectedYear, selectedMake]);
  
  useEffect(() => {
    if (selectedYear && selectedMake && selectedModel) {
      fetchMongoDBData('/vehicles/search', setTrims, { year: selectedYear, make: selectedMake, model: selectedModel });
    } else {
      setTrims([]);
    }
  }, [selectedYear, selectedMake, selectedModel]);

  const handleVehicleSelection = () => {
    if (selectedYear && selectedMake && selectedModel && selectedTrim) {
      onVehicleSelect({ year: selectedYear, make: selectedMake, model: selectedModel, trim: selectedTrim });
    }
  };

  return (
    <div className="vehicle-search">
      <select value={selectedYear} onChange={(e) => setSelectedYear(e.target.value)}>
        <option value="">Select Year</option>
        {[...Array(2025 - 1950 + 1).keys()].map((_, i) => (
          <option key={1950 + i} value={1950 + i}>{1950 + i}</option>
        ))}
      </select>

      <select value={selectedMake} onChange={(e) => setSelectedMake(e.target.value)} disabled={!selectedYear}>
        <option value="">Select Make</option>
        {makes.map((make) => (
          <option key={make} value={make}>{make}</option>
        ))}
      </select>

      <select value={selectedModel} onChange={(e) => setSelectedModel(e.target.value)} disabled={!selectedMake}>
        <option value="">Select Model</option>
        {models.map((model) => (
          <option key={model} value={model}>{model}</option>
        ))}
      </select>

      <select value={selectedTrim} onChange={(e) => setSelectedTrim(e.target.value)} disabled={!selectedModel}>
        <option value="">Select Trim</option>
        {trims.map((trim) => (
          <option key={trim} value={trim}>{trim}</option>
        ))}
      </select>

      <button onClick={handleVehicleSelection} disabled={!selectedTrim}>Select Vehicle</button>
    </div>
  );
}

export default VehicleSearch;
