import React, { useState, useEffect } from 'react';
import axiosAppointmentInstance from '../../../utils/axiosAppointment';
import './EditService.css';

const EditService = ({ onClose, onRefresh, service, isAdmin }) => {
  const [formData, setFormData] = useState({
    name: '',
    description: '',
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  // Prepopulate form with service data
  useEffect(() => {
    if (service) {
      setFormData({
        name: service.name || '',
        description: service.description || '',
      });
    }
  }, [service]);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage('');

    try {
      const endpoint = isAdmin
        ? `/admin/services/${service._id}`
        : `/services/${service._id}`;
      await axiosAppointmentInstance.put(endpoint, formData);

      setMessage('Service updated successfully.');
      onRefresh(); // Refresh the list of services
      onClose(); // Close the form
    } catch (error) {
      setMessage(
        error.response?.data?.message || 'Failed to update service. Please try again.'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="edit-service-modal">
      <div className="modal-content">
        <h3>Edit Service</h3>
        {message && <p className="feedback-message">{message}</p>}

        <form onSubmit={handleSubmit}>
          <label>
            Service Name:
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              required
              placeholder="Enter service name"
            />
          </label>
          <label>
            Description:
            <textarea
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              placeholder="Enter service description"
            ></textarea>
          </label>

          <div className="form-actions">
            <button type="submit" disabled={loading}>
              {loading ? 'Updating...' : 'Update Service'}
            </button>
            <button type="button" onClick={onClose}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditService;
