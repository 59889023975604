import React, { useState, useEffect } from 'react';
import axiosAppointment from '../../../utils/axiosAppointment';
import './EditBrand.css';

const EditBrand = ({ onClose, onRefresh, brand, isAdmin }) => {
  const [formData, setFormData] = useState({
    name: '',
    description: '',
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  // Prepopulate form with brand data
  useEffect(() => {
    if (brand) {
      setFormData({
        name: brand.name || '',
        description: brand.description || '',
      });
    }
  }, [brand]);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage('');

    try {
      const endpoint = isAdmin
        ? `/admin/brands/${brand._id}`
        : `/brands/${brand._id}`;
      await axiosAppointment.put(endpoint, formData);

      setMessage('Brand updated successfully.');
      onRefresh(); // Refresh the list of brands
      onClose(); // Close the form
    } catch (error) {
      setMessage(
        error.response?.data?.message || 'Failed to update brand. Please try again.'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="edit-brand-modal">
      <div className="modal-content">
        <h3>Edit Brand</h3>
        {message && <p className="feedback-message">{message}</p>}

        <form onSubmit={handleSubmit}>
          <label>
            Brand Name:
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              required
              placeholder="Enter brand name"
            />
          </label>
          
          <div className="form-actions">
            <button type="submit" disabled={loading}>
              {loading ? 'Updating...' : 'Update Brand'}
            </button>
            <button type="button" onClick={onClose}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditBrand;
