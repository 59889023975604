import React, { useState } from 'react';
import axiosAppointmentInstance from '../../../utils/axiosAppointment';
import './DeleteService.css';

const DeleteService = ({ onClose, onRefresh, service, isAdmin }) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const handleDelete = async () => {
    setLoading(true);
    setMessage('');

    try {
      const endpoint = isAdmin
        ? `/admin/services/${service._id}`
        : `/services/${service._id}`;
      await axiosAppointmentInstance.delete(endpoint);

      setMessage('Service deleted successfully.');
      onRefresh(); // Refresh the service list
      setTimeout(() => {
        onClose(); // Close the modal after success message
      }, 1500);
    } catch (error) {
      setMessage(
        error.response?.data?.message || 'Failed to delete service. Please try again.'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="delete-service-modal">
      <div className="modal-content">
        <h3>Delete Service</h3>
        <p>Are you sure you want to delete the service "{service.name}"?</p>
        {message && <p className="feedback-message">{message}</p>}

        <div className="modal-actions">
          <button className="btn-cancel" onClick={onClose} disabled={loading}>
            Cancel
          </button>
          <button
            className="btn-delete"
            onClick={handleDelete}
            disabled={loading}
          >
            {loading ? 'Deleting...' : 'Delete'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteService;
