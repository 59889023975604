import React, { useState, useEffect } from 'react';
import axiosAppointment from '../../../utils/axiosAppointment';
import EditBrand from './EditBrand';
import DeleteBrand from './DeleteBrand';
import './ListBrands.css';

const ListBrands = ({ isAdmin }) => {
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [showEditBrand, setShowEditBrand] = useState(false);
  const [showDeleteBrand, setShowDeleteBrand] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState(null);

  // Fetch brands
  const fetchBrands = async () => {
    try {
      setLoading(true);
      const endpoint = isAdmin ? '/admin/brands' : '/brands';
      const response = await axiosAppointment.get(endpoint);
      setBrands(response.data);
      setError('');
    } catch (err) {
      setError(
        err.response?.data?.message || 'Failed to fetch brands. Please try again.'
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBrands();
  }, []);

  // Open Edit Brand Modal
  const handleEditClick = (brand) => {
    setSelectedBrand(brand);
    setShowEditBrand(true);
  };

  // Open Delete Brand Modal
  const handleDeleteClick = (brand) => {
    setSelectedBrand(brand);
    setShowDeleteBrand(true);
  };

  return (
    <div className="list-brands">
      <h3>{isAdmin ? 'Admin Brands' : 'My Brands'}</h3>
      {error && <p className="error-message">{error}</p>}
      {loading ? (
        <p>Loading...</p>
      ) : (
        <table className="brands-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {brands.map((brand) => (
              <tr key={brand._id}>
                <td>{brand.name}</td>
                <td>
                  <button onClick={() => handleEditClick(brand)}>Edit</button>
                  <button onClick={() => handleDeleteClick(brand)}>Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {/* Edit Brand Modal */}
      {showEditBrand && (
        <EditBrand
          brand={selectedBrand}
          onClose={() => setShowEditBrand(false)}
          onRefresh={fetchBrands}
          isAdmin={isAdmin}
        />
      )}

      {/* Delete Brand Modal */}
      {showDeleteBrand && (
        <DeleteBrand
          brand={selectedBrand}
          onClose={() => setShowDeleteBrand(false)}
          onRefresh={fetchBrands}
          isAdmin={isAdmin}
        />
      )}
    </div>
  );
};

export default ListBrands;
