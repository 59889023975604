import React, { useEffect, useState } from 'react';
import axiosAppointment from '../../utils/axiosAppointment'; // Import the custom Axios instance
import PricingForm from './PricingForm';
import DeleteConfirmationModal from './DeleteConfirmationModal';

const PricingDashboard = () => {
  const [pricingData, setPricingData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [selectedPricing, setSelectedPricing] = useState(null); // For editing
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  // Fetch Pricing Data
  const fetchPricingData = async () => {
    try {
      setLoading(true);
      const response = await axiosAppointment.get('/pricing'); // No /api needed
      setPricingData(response.data);
    } catch (error) {
      console.error('Error fetching pricing data:', error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPricingData();
  }, []);

  // Open Add/Edit Form
  const handleAddPricing = () => {
    setSelectedPricing(null); // Clear selected pricing for add
    setShowForm(true);
  };

  const handleEditPricing = (pricing) => {
    setSelectedPricing(pricing); // Set selected pricing for edit
    setShowForm(true);
  };

  const handleDeletePricing = (id) => {
    setDeleteId(id); // Set ID for deletion
    setShowDeleteModal(true);
  };

  const confirmDelete = async () => {
    try {
      await axiosAppointment.delete(`/pricing/${deleteId}`); // Delete pricing
      setPricingData((prev) => prev.filter((item) => item._id !== deleteId)); // Remove from UI
      setShowDeleteModal(false);
    } catch (error) {
      console.error('Error deleting pricing:', error.message);
    }
  };

  return (
    <div className="pricing-dashboard">
      <div className="header">
        <h2>Pricing Dashboard</h2>
        <button onClick={handleAddPricing}>Add Pricing</button>
      </div>

      {loading ? (
        <p>Loading...</p>
      ) : (
        <table className="pricing-table">
          <thead>
            <tr>
              <th>Service</th>
              <th>Sub-Service</th>
              <th>Brand</th>
              <th>Sub-Brand</th>
              <th>Pricing Method</th>
              <th>Base Price</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {pricingData.map((pricing) => (
              <tr key={pricing._id}>
                <td>{pricing.serviceId}</td>
                <td>{pricing.subServiceId}</td>
                <td>{pricing.brandId}</td>
                <td>{pricing.subBrandId}</td>
                <td>{pricing.pricingMethod}</td>
                <td>${pricing.basePrice.toFixed(2)}</td>
                <td>
                  <button onClick={() => handleEditPricing(pricing)}>Edit</button>
                  <button onClick={() => handleDeletePricing(pricing._id)}>Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {/* Pricing Form for Add/Edit */}
      {showForm && (
        <PricingForm
          onClose={() => setShowForm(false)}
          onRefresh={fetchPricingData}
          selectedPricing={selectedPricing}
        />
      )}

      {/* Delete Confirmation Modal */}
      {showDeleteModal && (
        <DeleteConfirmationModal
          onClose={() => setShowDeleteModal(false)}
          onConfirm={confirmDelete}
        />
      )}
    </div>
  );
};

export default PricingDashboard;
