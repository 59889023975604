import React from 'react';

const DeleteConfirmationModal = ({ onClose, onConfirm }) => {
  return (
    <div className="delete-confirmation-modal">
      <div className="modal-content">
        <h3>Confirm Deletion</h3>
        <p>Are you sure you want to delete this pricing configuration? This action cannot be undone.</p>
        <div className="modal-actions">
          <button className="btn-cancel" onClick={onClose}>
            Cancel
          </button>
          <button className="btn-confirm" onClick={onConfirm}>
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmationModal;
