import React, { useEffect, useState } from 'react';
import axiosAppointment from '../../utils/axiosAppointment';

const PricingForm = ({ onClose, onRefresh, selectedPricing }) => {
  const [formData, setFormData] = useState({
    serviceId: '',
    subServiceId: '',
    brandId: '',
    subBrandId: '',
    pricingMethod: 'perPanel',
    basePrice: '',
    panelPricing: [],
    packagePricing: [],
    combinationDiscounts: [],
  });

  const [isLoading, setIsLoading] = useState(false);

  // Populate form in edit mode
  useEffect(() => {
    if (selectedPricing) {
      setFormData({
        serviceId: selectedPricing.serviceId || '',
        subServiceId: selectedPricing.subServiceId || '',
        brandId: selectedPricing.brandId || '',
        subBrandId: selectedPricing.subBrandId || '',
        pricingMethod: selectedPricing.pricingMethod || 'perPanel',
        basePrice: selectedPricing.basePrice || '',
        panelPricing: selectedPricing.panelPricing || [],
        packagePricing: selectedPricing.packagePricing || [],
        combinationDiscounts: selectedPricing.combinationDiscounts || [],
      });
    }
  }, [selectedPricing]);

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Handle array input changes (e.g., panelPricing)
  const handleArrayChange = (arrayName, index, field, value) => {
    const updatedArray = [...formData[arrayName]];
    updatedArray[index][field] = value;
    setFormData((prev) => ({ ...prev, [arrayName]: updatedArray }));
  };

  // Add new items to arrays (e.g., panelPricing or packagePricing)
  const handleAddArrayItem = (arrayName) => {
    setFormData((prev) => ({
      ...prev,
      [arrayName]: [...prev[arrayName], {}],
    }));
  };

  // Remove items from arrays
  const handleRemoveArrayItem = (arrayName, index) => {
    const updatedArray = [...formData[arrayName]];
    updatedArray.splice(index, 1);
    setFormData((prev) => ({ ...prev, [arrayName]: updatedArray }));
  };

  // Submit form data
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      if (selectedPricing) {
        // Update existing pricing
        await axiosAppointment.put(`/pricing/${selectedPricing._id}`, formData);
      } else {
        // Create new pricing
        await axiosAppointment.post('/pricing', formData);
      }
      onRefresh(); // Refresh the dashboard
      onClose(); // Close the form
    } catch (error) {
      console.error('Error saving pricing:', error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="pricing-form">
      <h3>{selectedPricing ? 'Edit Pricing' : 'Add Pricing'}</h3>
      <form onSubmit={handleSubmit}>
        <label>
          Service:
          <input
            type="text"
            name="serviceId"
            value={formData.serviceId}
            onChange={handleInputChange}
            required
          />
        </label>
        <label>
          Sub-Service:
          <input
            type="text"
            name="subServiceId"
            value={formData.subServiceId}
            onChange={handleInputChange}
            required
          />
        </label>
        <label>
          Brand:
          <input
            type="text"
            name="brandId"
            value={formData.brandId}
            onChange={handleInputChange}
            required
          />
        </label>
        <label>
          Sub-Brand:
          <input
            type="text"
            name="subBrandId"
            value={formData.subBrandId}
            onChange={handleInputChange}
            required
          />
        </label>
        <label>
          Pricing Method:
          <select
            name="pricingMethod"
            value={formData.pricingMethod}
            onChange={handleInputChange}
          >
            <option value="perPanel">Per Panel</option>
            <option value="perSquareFoot">Per Square Foot</option>
            <option value="perPackage">Per Package</option>
          </select>
        </label>
        <label>
          Base Price:
          <input
            type="number"
            name="basePrice"
            value={formData.basePrice}
            onChange={handleInputChange}
            required
          />
        </label>

        {/* Dynamic Fields for Panel Pricing */}
        {formData.pricingMethod === 'perPanel' && (
          <>
            <h4>Panel Pricing</h4>
            {formData.panelPricing.map((panel, index) => (
              <div key={index} className="panel-pricing-row">
                <input
                  type="text"
                  placeholder="Panel Name"
                  value={panel.name || ''}
                  onChange={(e) =>
                    handleArrayChange('panelPricing', index, 'name', e.target.value)
                  }
                />
                <input
                  type="number"
                  placeholder="Price"
                  value={panel.price || ''}
                  onChange={(e) =>
                    handleArrayChange('panelPricing', index, 'price', e.target.value)
                  }
                />
                <button
                  type="button"
                  onClick={() => handleRemoveArrayItem('panelPricing', index)}
                >
                  Remove
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={() => handleAddArrayItem('panelPricing')}
            >
              Add Panel
            </button>
          </>
        )}

        {/* Submit Button */}
        <button type="submit" disabled={isLoading}>
          {isLoading ? 'Saving...' : 'Save'}
        </button>
        <button type="button" onClick={onClose}>
          Cancel
        </button>
      </form>
    </div>
  );
};

export default PricingForm;
