import React, { useState } from 'react';
import axiosAppointment from '../../../utils/axiosAppointment';
import './DeleteBrand.css';

const DeleteBrand = ({ onClose, onRefresh, brand, isAdmin }) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const handleDelete = async () => {
    setLoading(true);
    setMessage('');

    try {
      const endpoint = isAdmin
        ? `/admin/brands/${brand._id}`
        : `/brands/${brand._id}`;
      await axiosAppointment.delete(endpoint);

      setMessage('Brand deleted successfully.');
      onRefresh(); // Refresh the list of brands
      setTimeout(() => {
        onClose(); // Close the modal after success message
      }, 1500);
    } catch (error) {
      setMessage(
        error.response?.data?.message || 'Failed to delete brand. Please try again.'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="delete-brand-modal">
      <div className="modal-content">
        <h3>Delete Brand</h3>
        <p>Are you sure you want to delete the brand "{brand.name}"?</p>
        {message && <p className="feedback-message">{message}</p>}

        <div className="modal-actions">
          <button className="btn-cancel" onClick={onClose} disabled={loading}>
            Cancel
          </button>
          <button
            className="btn-delete"
            onClick={handleDelete}
            disabled={loading}
          >
            {loading ? 'Deleting...' : 'Delete'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteBrand;
