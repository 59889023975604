import React, { useState, useEffect } from 'react';
import AddService from '../ServiceCrud/AddService';
import EditService from '../ServiceCrud/EditService';
import DeleteService from '../ServiceCrud/DeleteService';
import ListServices from '../ServiceCrud/ListServices';
import ListBrands from '../../Brands/BrandCrud/ListBrands';
import AddBrand from '../../Brands/BrandCrud/AddBrand';
import EditBrand from '../../Brands/BrandCrud/EditBrand';
import DeleteBrand from '../../Brands/BrandCrud/DeleteBrand';
import axiosAppointmentInstance from '../../../utils/axiosAppointment';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ServiceDashboard = ({ isAdmin }) => {
  const [activeTab, setActiveTab] = useState('services');
  const [services, setServices] = useState([]);
  const [brands, setBrands] = useState([]);
  const [loadingServices, setLoadingServices] = useState(false);
  const [loadingBrands, setLoadingBrands] = useState(false);

  const [showAddService, setShowAddService] = useState(false);
  const [showEditService, setShowEditService] = useState(false);
  const [showDeleteService, setShowDeleteService] = useState(false);
  const [selectedService, setSelectedService] = useState(null);

  const [showAddBrand, setShowAddBrand] = useState(false);
  const [showEditBrand, setShowEditBrand] = useState(false);
  const [showDeleteBrand, setShowDeleteBrand] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState(null);

  const refreshServices = async () => {
    try {
      setLoadingServices(true);
      const response = await axiosAppointmentInstance.get('/services');
      setServices(response.data);
      toast.success('Services loaded successfully!', { toastId: 'services-load' });
    } catch (error) {
      console.error('Error refreshing services:', error);
      toast.error('Failed to refresh services.', { toastId: 'services-error' });
    } finally {
      setLoadingServices(false);
    }
  };

  const refreshBrands = async () => {
    try {
      setLoadingBrands(true);
      const response = await axiosAppointmentInstance.get('/brands');
      setBrands(response.data);
      toast.success('Brands loaded successfully!', { toastId: 'brands-load' });
    } catch (error) {
      console.error('Error refreshing brands:', error);
      toast.error('Failed to refresh brands.', { toastId: 'brands-error' });
    } finally {
      setLoadingBrands(false);
    }
  };

  useEffect(() => {
    if (activeTab === 'services') {
      refreshServices();
    } else if (activeTab === 'brands') {
      refreshBrands();
    }
  }, [activeTab]);

  return (
    <div className="service-dashboard">
      <div className="tabs">
        <button
          className={activeTab === 'services' ? 'active' : ''}
          onClick={() => setActiveTab('services')}
        >
          Services
        </button>
        <button
          className={activeTab === 'brands' ? 'active' : ''}
          onClick={() => setActiveTab('brands')}
        >
          Brands
        </button>
      </div>

      <div className="tab-content">
        {activeTab === 'services' && (
          <>
            <div className="header">
              <h2>{isAdmin ? 'Admin Services' : 'My Services'}</h2>
              <button onClick={() => setShowAddService(true)}>Add New Service</button>
            </div>
            {loadingServices ? (
              <p>Loading services...</p>
            ) : (
              <ListServices
                services={services}
                isAdmin={isAdmin}
                onEdit={(service) => {
                  setSelectedService(service);
                  setShowEditService(true);
                }}
                onDelete={(service) => {
                  setSelectedService(service);
                  setShowDeleteService(true);
                }}
                onRefresh={refreshServices}
              />
            )}
          </>
        )}

        {activeTab === 'brands' && (
          <>
            <div className="header">
              <h2>{isAdmin ? 'Admin Brands' : 'My Brands'}</h2>
              <button onClick={() => setShowAddBrand(true)}>Add New Brand</button>
            </div>
            {loadingBrands ? (
              <p>Loading brands...</p>
            ) : (
              <ListBrands
                brands={brands}
                isAdmin={isAdmin}
                onEdit={(brand) => {
                  setSelectedBrand(brand);
                  setShowEditBrand(true);
                }}
                onDelete={(brand) => {
                  setSelectedBrand(brand);
                  setShowDeleteBrand(true);
                }}
                onRefresh={refreshBrands}
              />
            )}
          </>
        )}
      </div>

      {/* Modals */}
      {showAddService && (
        <AddService
          onClose={() => {
            setShowAddService(false);
            refreshServices();
          }}
          isAdmin={isAdmin}
        />
      )}
      {showEditService && (
        <EditService
          service={selectedService}
          onClose={() => {
            setShowEditService(false);
            refreshServices();
          }}
          isAdmin={isAdmin}
        />
      )}
      {showDeleteService && (
        <DeleteService
          service={selectedService}
          onClose={() => {
            setShowDeleteService(false);
            refreshServices();
          }}
          isAdmin={isAdmin}
        />
      )}

      {showAddBrand && (
        <AddBrand
          onClose={() => {
            setShowAddBrand(false);
            refreshBrands();
          }}
          isAdmin={isAdmin}
        />
      )}
      {showEditBrand && (
        <EditBrand
          brand={selectedBrand}
          onClose={() => {
            setShowEditBrand(false);
            refreshBrands();
          }}
          isAdmin={isAdmin}
        />
      )}
      {showDeleteBrand && (
        <DeleteBrand
          brand={selectedBrand}
          onClose={() => {
            setShowDeleteBrand(false);
            refreshBrands();
          }}
          isAdmin={isAdmin}
        />
      )}
    </div>
  );
};

export default ServiceDashboard;
