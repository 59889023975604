import React, { useState } from 'react';
import axiosAppointmentInstance from '../../../utils/axiosAppointment';
import './AddBrand.css';

const AddBrand = ({ onClose, onRefresh, isAdmin }) => {
    const [name, setName] = useState('');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);
      setMessage('');
  
      try {
        const endpoint = isAdmin ? '/admin/brands' : '/brands';
        await axiosAppointmentInstance.post(endpoint, { name });
        setMessage('Brand added successfully!');
  
        // Trigger the refresh before closing
        await onRefresh();
        setTimeout(() => {
          onClose();
        }, 1000);
      } catch (error) {
        setMessage(error.response?.data?.message || 'Failed to add brand. Please try again.');
      } finally {
        setLoading(false);
      }
    };
  
    return (
      <div className="add-brand-modal">
        <div className="modal-content">
          <h3>Add New Brand</h3>
          {message && <p className="feedback-message">{message}</p>}
  
          <form onSubmit={handleSubmit}>
            <label>
              Brand Name:
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                placeholder="Enter brand name"
              />
            </label>
  
            <div className="form-actions">
              <button type="submit" disabled={loading}>
                {loading ? 'Adding...' : 'Add Brand'}
              </button>
              <button type="button" onClick={onClose}>
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  };
  
  export default AddBrand;